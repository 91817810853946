<template>
  <el-container class="main-container">
    <el-row>
      <el-button @click="add">新增</el-button>
    </el-row>
    <el-card class="mt20">
      <el-row :gutter="10">
        <el-col :span="4">
          <el-input
            v-model="queryInfo.query"
            placeholder="请输入内容"
            clearable
            @clear="query"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="query"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <ele-select v-model="queryInfo.state" placeholder="状态筛选"
            :options="stateList" @change="query"></ele-select>
        </el-col>
      </el-row>
      <ele-table
        :columns-desc="columns"
        :request-fn="getAppBannerGroups"
        :right-buttons="rightButtons"
        :is-show-top-delete="false"
        :is-show-right-delete="false"
        ref="table"
      ></ele-table>
    </el-card>
    <ele-form-dialog
      v-model="formData"
      :form-desc="formDesc"
      :title="isEdit ? '编辑分组' : '新增分组'"
      :request-fn="handleSubmit"
      :visible.sync="dialogVisible"
      @closed="handleClosed"
    >
    </ele-form-dialog>
    <ele-form-dialog
      width="70%"
      v-model="selectBannerFormData"
      :form-desc="selectBannerFormDesc"
      title="选择横幅"
      :request-fn="handleSelectBannersSubmit"
      :visible.sync="selectBannerDialogVisible"
      @closed="handleClosed"
    ></ele-form-dialog>
  </el-container>
</template>

<script>
import { tableStateList, stateList } from "../../assets/js/constant";
export default {
  data() {
    return {
      queryInfo: {
        query: "",
        state: "",
      },
      stateList: stateList,
      columns: {
        name: {
          text: "分组名称",
        },
        startTimeStr: {
          text: "起始时间",
          type: "text",
        },
        endTimeStr: {
          text: "结束时间",
          type: "text",
        },
        desc: {
          text: "描述",
        },
        state: {
          text: "状态",
          type: "status",
          options: tableStateList,
        },
      },
      rightButtons: [
        {
          text: "编辑",
          attrs: {
            type: "primary",
          },
          click: (id, index, data) => {
            this.edit(data);
          },
        },
        {
          text: "选择横幅",
          attrs: {
            type: "success",
          },
          click: (id, index, data) => {
            this.selectBanners(data);
          },
        },
      ],
      bannerList: [],
      formData: {},
      formDesc: {
        name: {
          label: "分组名称",
          type: "input",
          require: true,
        },
        startTime: {
          label: "起始时间",
          type: "datetime",
          attrs: {
            valueFormat: "timestamp",
          },
        },
        endTime: {
          label: "结束时间",
          type: "datetime",
          attrs: {
            valueFormat: "timestamp",
          },
        },
        desc: {
          label: "描述",
          type: "textarea",
        },
        state: {
          label: "启用状态",
          type: "radio",
          options: stateList,
        },
      },
      isEdit: false,
      dialogVisible: false,
      selectBannerFormData: {},
      selectBannerFormDesc: {
        banners: {
          label: "横幅",
          type: "table-editor",
          layout: 24,
          attrs: {
            isShowDelete: true,
            rules: {
              level: { required: true, message: 'level不能为空'}
            },
            columns: [
              {
                prop: "bannerId",
                label: "横幅名称",
                content: {
                  type: 'el-select',
                  options: []
                }
              },
              {
                prop: 'level',
                label: '顺序',
                content: {
                  type: 'el-input',
                  attrs: {
                    type: 'number',
                    step: '1'
                  }
                }
              },
            ],
          }
        },
      },
      selectBannerDialogVisible: false,
    };
  },
  created() {
    this.getBannerList();
  },
  methods: {
    query() {
      this.$refs.table.getData();
    },
    async getBannerList() {
      const { data: res } = await this.$api.getAppBannerList();
      if (res.code > 0) {
        return this.$notify.error({
          title: '错误',
          message: res.message
        })
      }
      this.bannerList = res.data;
    },
    async getAppBannerGroups(params) {
      const { data: res } = await this.$api.getAppBannerGroups(
        Object.assign(params, this.queryInfo)
      );
      if (res.code > 0) {
        return this.$notify.error({
          title: '错误',
          message: res.message
        })
      }
      return res.data;
    },
    add() {
      this.isEdit = false;
      this.dialogVisible = true;
    },
    edit(data) {
      this.isEdit = true;
      this.formData = data;
      this.dialogVisible = true;
    },
    selectBanners(data) {
      this.selectBannerFormData.groupId = data.id;
      this.selectBannerFormDesc.banners.attrs.columns[0].content.options = this.bannerList
      this.selectBannerFormData.banners = data.banners
      this.selectBannerDialogVisible = true;
    },
    async handleSelectBannersSubmit(data) {
      const { data: res } = await this.$api.addBanners(data)
      if (res.code > 0) {
        return this.$notify.error({
          title: '错误',
          message: res.message
        })
      }
      this.selectBannerDialogVisible = false
      this.query()
      this.$notify.success({
        title: '成功',
        message: res.message
      })
    }, 
    async handleSubmit(data) {
      const { data: res } = await this.$api.editAppBannerGroup(data);
      if (res.code > 0) {
        return this.$notify.error({
          title: '错误',
          message: res.message
        })
      }
      this.dialogVisible = false;
      this.query();
      this.$notify.success({
        title: '成功',
        message: res.message
      })
    },
    handleClosed() {
      this.formData = {};
      this.selectBannerFormData = {}
    },
  },
};
</script>

<style scoped>
.el-col-16 {
  width: 100% !important;
}
</style>